<template>
    <b-modal 
            id="add-new-audit-modal" 
            v-model="isAddNewAuditSidebarActive" 
            size="md"
            :title="`${paymentData.is_audit ? '' : '取消'}審核`" 
            centered 
            @hidden="resetForm"
            @change="(val) => $emit('update:is-add-new-audit-sidebar-active', val)"
            :ok-title="`${paymentData.is_audit ? '' : '取消'}審核`"
            cancel-title="取消"
            @ok.prevent="handleOk"
            >
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- 審核日期-->
                    <validation-provider #default="validationContext" name="audit_date" rules="required"
                        v-if="paymentData.is_audit">
                        <b-form-group class="audit_date required" label="審核日期" label-for="audit_date">
                            <b-form-datepicker id="audit_date" :state="getValidationState(validationContext)"
                                placeholder="Choose a date" local="en" v-model="paymentData.audit_date"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!-- 內部備註 -->
                    <b-form-group class="audit_remark required" label="內部備註" label-for="audit_remark">
                        <b-form-textarea v-model="paymentData.audit_remark" placeholder="內部備註" rows="3" />
                    </b-form-group>

                    <!-- AUDIT -->
                    <b-form-group class="audit_date required" label="確認核數" label-for="audit_date" label-cols-md="4">
                        <b-form-checkbox checked="true" name="check-button" switch inline
                            v-model="paymentData.is_audit">
                            {{ (paymentData.is_audit) ? 'Yes' : 'No' }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-overlay>
        </validation-observer>
    </b-modal>
</template>


<script>
import {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    BRow,
    BCol
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    directives: {
        Ripple,
    },
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormTextarea,
        BFormCheckbox,
        vSelect,
        BOverlay,
        BFormDatepicker,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewAuditSidebarActive",
        event: "update:is-add-new-audit-sidebar-active",
    },
    props: {
        isAddNewAuditSidebarActive: {
            type: Boolean,
            required: true,
        },
        id: {
            type: Number,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            max,
        };
    },
    methods:{
        handleOk() {
        this.$refs.refFormObserver.validate().then((valid) => {
            if (valid) {
                this.onSubmit();
            }
        });
    },
    },
    setup(props, { emit }) {
        const loading = ref(false);

        const blankPaymentData = {
            id: 0,
            is_audit: true,
            audit_date: "",
            audit_remark: "",
        };
        const button_text = ref("提交");

        const paymentData = ref(JSON.parse(JSON.stringify(blankPaymentData)));

        const resetpaymentData = () => {
            paymentData.value = JSON.parse(JSON.stringify(blankPaymentData));
        };

        const toast = useToast();

        watch(
            () => [props.id, props.isAddNewAuditSidebarActive],
            () => {
                if (props.id > 0 && props.isAddNewAuditSidebarActive) {
                    paymentData.value = { ...props.data }
                    paymentData.value.id = props.data.id;
                    paymentData.value.is_audit = props.data.is_audit == 1 ? true : false;
                    if (paymentData.value.id > 0) {
                        button_text.value = "提交";
                    } else {
                        button_text.value = "提交";
                    }
                } else {
                    button_text.value = "提交";
                }
            }
        );

        const onSubmit = () => {
            loading.value = true;
            store
                .dispatch("payment/audit", paymentData.value)
                .then((response) => {
                    loading.value = false;
                    emit("update:is-add-new-audit-sidebar-active", false);
                    emit("refetch-data");
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            text: response.data.message,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((error) => {
                    loading.value = false;
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Fail",
                            text: error.response.data.message,
                            icon: "HeartIcon",
                            variant: "danger",
                        },
                    });
                });
        };



        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetpaymentData);

        return {
            paymentData,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            button_text,
            loading,
        };
    },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-payment-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>