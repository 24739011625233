<template>
  <b-sidebar
    id="add-new-payment-sidebar"
    :visible="isAddNewPaymentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-payment-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Audit</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- 審核日期-->
            <validation-provider
              #default="validationContext"
              name="audit_date"
              rules="required"
              v-if="paymentData.is_audit"
            >
              <b-form-group
                class="audit_date required"
                label="審核日期"
                label-for="audit_date"
                
              >
                <b-form-datepicker
                  id="audit_date"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a date"
                  local="en"
                  v-model="paymentData.audit_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- 內部備註 -->
              <b-form-group
                class="audit_remark required"
                label="內部備註"
                label-for="audit_remark"                
              >
                <b-form-textarea v-model="paymentData.audit_remark" placeholder="內部備註" rows="3" />
              </b-form-group>
           
            <!-- AUDIT -->
            <b-form-group
            class="audit_date required"
                label="確認核數"
                label-for="audit_date"
               label-cols-md="4"
            >
              <b-form-checkbox
                checked="true"
                name="check-button"
                switch
                inline
                v-model="paymentData.is_audit"
              >
                {{ (paymentData.is_audit)?'Yes':'No' }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";


export default {
  directives: {
    Ripple,
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPaymentSidebarActive",
    event: "update:is-add-new-payment-sidebar-active",
  },
  props: {
    isAddNewPaymentSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankPaymentData = {
      id: 0,
      is_audit: true,
      audit_date: "",
      audit_remark: "",
    };
    const button_text = ref("提交");

    const paymentData = ref(JSON.parse(JSON.stringify(blankPaymentData)));

    const resetpaymentData = () => {
      paymentData.value = JSON.parse(JSON.stringify(blankPaymentData));
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewPaymentSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewPaymentSidebarActive) {
          paymentData.value = {...props.data}
          paymentData.value.id = props.data.id;
          paymentData.value.is_audit = props.data.is_audit == 1 ? true : false;
          if (paymentData.value.id > 0) {
            button_text.value = "提交";
          } else {
            button_text.value = "提交";
          }
        } else {
          button_text.value = "提交";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch("payment/audit", paymentData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-payment-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetpaymentData);

    return {
      paymentData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-payment-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>