<template>
  <div>
    <modal-document :id="modalId" :invoice_id="invoice_id" />
    <modal-pdf id="-payment-list" :pdfUrl="pdfUrl" />
    <modal-audit :is-add-new-audit-sidebar-active.sync="isAddNewAuditSidebarActive" :id="id" :data="paymentData"
      @refetch-data="refetchData" v-if="ability.can('update', 'payments')">
    </modal-audit>

    <modal-payment :is-add-new-payment-sidebar-active.sync="isAddNewPaymentSidebarActive" :id="id" :data="paymentData"
      :payment-options="paymentOptions" :receipt-template-options="receiptTemplateOptions" @refetch-data="refetchData"
      v-if="ability.can('update', 'payments')" />

    <payment-list-filter :user-filter.sync="userFilter" :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter" :user-options="userOptions" :client-options="clientOptions"
      :client-filter.sync="clientFilter" :delete-filter.sync="deleteFilter"
      :payment-status-filter.sync="paymentStatusFilter" :payment-status-options="paymentStatusOptions" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="搜尋..." />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay :show="tableLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <b-table ref="refPaymentListTable" class="payment-relative" :items="fetchPayments" responsive
          :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
          empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
          <!-- Column: Client Code -->
          <template #cell(client)="data">
            <b-link :to="{
              name: 'clients-detail',
              params: { id: data.item.invoice.client.id },
            }">{{ data.item.invoice.client.client_code }}</b-link>
          </template>

          <template #cell(document)="data">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
              @click="downloadDocument(data.item.invoice_id)">
              <feather-icon icon="DownloadCloudIcon" />
            </b-button>
          </template>

          <template #cell(receipt)="data">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
              @click="downloadReceipt(data.item.id)" v-if="data.item.is_audit">
              <feather-icon icon="FileTextIcon" /> {{ data.item.receipt_id }}
            </b-button>
          </template>

          <!-- Column: PAYMENT AMOUNT -->
          <template #cell(invoice)="data">
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon"
              :to="{ name: 'invoices-preview', params: { id: data.item.invoice_id } }">
              <feather-icon icon="FileTextIcon" />
            </b-button>
          </template>
          <!-- Column: PAYMENT REMARK -->
          <template #cell(payment_remark)="data">
            <div v-html="data.item.payment_remark"></div>
          </template>
          <template #cell(created_at)="data">
            <div v-html="data.item.payment_date"></div>
          </template>
          <template #cell(payment_amount)="data">
            {{ getNumberFormat(data.item.payment_amount) }}
          </template>
          <!-- Column: IS AUDIT -->
          <template #cell(is_audit)="data">
            <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
          </template>
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item v-if="ability.can('update', 'payments')" @click="handleAudit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ data.item.is_audit ? '取消審核' : '審核' }}</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="ability.can('update', 'payments')" @click="handleEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="ability.can('delete', 'payments') && data.item.deleted_at == null"
                @click="confirmDelete(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">刪除</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="ability.can('delete', 'payments') && data.item.deleted_at"
                @click="confirmRestore(data.item.id)">
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">恢復</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalPayments" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import paymentStoreModule from "../paymentStoreModule";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule"
import usePaymentsList from "./usePaymentsList";
import PaymentAddNew from "./PaymentListAddNew.vue";
import Ripple from "vue-ripple-directive";
import PaymentListFilter from "./PaymentListFilter"
import ModalDocument from '../modal/ModalDocument.vue';
import ModalAudit from '../modal/ModalAudit.vue';
import ModalPayment from '@/views/payment/modal/ModalPayment.vue';
import ModalPdf from '@/layouts/components/ModalPdf.vue';

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    PaymentAddNew,
    PaymentListFilter,
    ModalDocument,
    ModalAudit,
    ModalPayment,
    ModalPdf,
    BOverlay
  },
  methods: {
    downloadReceipt(id) {
      this.tableLoading = true;
      this.$store
        .dispatch("payment/downloadReceipt", { id })
        .then((response) => {
          console.log('response', response)
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-payment-list");
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.tableLoading = false;
        });
    },
    downloadDocument(invoice_id) {
      this.invoice_id = invoice_id
      this.$bvModal.show(`modal-document${this.modalId}`);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    handleEdit(item) {
      this.paymentData = { ...item }
      this.id = item.id;
      this.isAddNewPaymentSidebarActive = true;
    },
    handleAudit(item) {
      this.resetPaymentData();
      this.id = item.id
      this.paymentData = { ...item }
      this.paymentData.is_audit = !this.paymentData.is_audit;
      this.isAddNewAuditSidebarActive = true;
    },
    resetPaymentData() {
      this.paymentData = {
        id: 0,
        is_audit: true,
        audit_date: '',
        audit_remark: '',
      };
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("payment/deletePayment", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmRestore(id) {
      this.$swal({
        title: "確認刪恢復?",
        showCancelButton: true,
        confirmButtonText: "恢復",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`payment/restorePayment`, id)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
              this.refetchStatistic();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      id: 0,
      paymentData: {},
      invoice_id: null,
      modalId: '-payment-list',
      pdfUrl: '',
    };
  },
  setup() {
    const PAYMENT_STORE_MODULE_NAME = "payment";
    const INVOICE_STORE_MODULE_NAME = "invoice";

    if (!store.hasModule(PAYMENT_STORE_MODULE_NAME))
      store.registerModule(PAYMENT_STORE_MODULE_NAME, paymentStoreModule);
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PAYMENT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_STORE_MODULE_NAME);
      if (store.hasModule(INVOICE_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    //Filter 
    const userOptions = ref([])
    const clientOptions = ref([])
    const paymentOptions = ref([])
    const receiptTemplateOptions = ref([])
    const tableLoading = ref(false)

    store.dispatch('app/fetchOptionList', { user: true, client: true, payment_method: true, receipt_template: true })
      .then(response => {
        userOptions.value = response.data.users;
        clientOptions.value = response.data.clients;
        paymentOptions.value = response.data.payment_methods;
        receiptTemplateOptions.value = response.data.receipt_templates;

      })
      .catch(error => {
        console.log('Error in fetching Option List', error)
      })

    const isAddNewAuditSidebarActive = ref(false);
    const isAddNewPaymentSidebarActive = ref(false);


    const {
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
      paymentStatusFilter,
      paymentStatusOptions
    } = usePaymentsList();

    return {
      //Sidebar
      isAddNewAuditSidebarActive,
      isAddNewPaymentSidebarActive,

      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalPayments,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPaymentListTable,
      refetchData,
      avatarText,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
      userOptions,
      clientOptions,
      receiptTemplateOptions,
      paymentOptions,
      tableLoading,
      paymentStatusFilter,
      paymentStatusOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>